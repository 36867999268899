import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { PlayCircleFilled } from '@ant-design/icons';
import Image from 'components/Image';
import Divider from 'components/Divider';

const HitboxContainer = styled.div`
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.primaryBackground};
`;

const Container = styled.div`
    position: relative;
    width: 90%;
    margin: 0 auto;
    margin-bottom: -20px;
`;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(102, 102, 102, 0.5);
`;

const SectionTitle = styled.h4`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 35px 0 25px;
    margin: 0;
`;

const Title = styled.h2`
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: white;
    padding: 20px 0;
    margin: 0;
    text-shadow: black 0px 0px 10px;
    margin: 0;
    @media (max-height: 992px) {
        font-size: 24px;
        line-height: 1.3;
    }
`;

const Category = styled.h3`
    a {
        :hover {
        }
        color: white;
    }
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: white;
    text-align: center;
    text-shadow: black 0px 0px 10px;
    margin: 0;
`;

const Content = styled.div`
    width: 100%;
`;

const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;

const IconWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
`;

const VideoIcon = styled(PlayCircleFilled).attrs({
    size: 92
})`
    font-size: 36px;
    color: white;
    text-shadow: black 0px 0px 10px;
`;

interface Props {
    video: Video;
}

const MainHitbox = ({ video }: Props): JSX.Element => (
    <HitboxContainer>
        <SectionTitle>Polecamy</SectionTitle>
        <Container>
            <Image
                type="index"
                src={video.openingImage}
                alt={video.title}
                width={792}
                height={527}
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
            />
            <Wrapper>
                <Overlay>
                    <Text>
                        <IconWrapper>
                            <Link href={`/wideo/${video.seoSlug}`}>
                                <VideoIcon />
                            </Link>
                        </IconWrapper>
                        {video.category && (
                            <>
                                <Link href={`/${video.category.seoSlug}`}>
                                    <Category>{video.category.title}</Category>
                                </Link>
                                <Divider width="100%" style={{ margin: '0 auto' }} />
                            </>
                        )}
                        <Content>
                            <Link href={`/wideo/${video.seoSlug}`}>
                                <Title>{video.title}</Title>
                            </Link>
                        </Content>
                    </Text>
                </Overlay>
            </Wrapper>
        </Container>
    </HitboxContainer>
);

export default MainHitbox;
